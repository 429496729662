import KexCartModel from 'Models/Cart/KexCartModel.interface';
import UpdateCartRequest from 'Models/Cart/UpdateCartRequest.interface';
import { useEffect, useState } from 'react';
import { EventDispatcher, NOTIFY_ACTION } from 'Shared/Common/EventDispatcher';
import Fetcher from 'Shared/Common/Fetcher';
import { IS_PRODUCTION_ENV } from 'Shared/Configs/EnvConfig';
import useSWR, { mutate } from 'swr';
import { API_ROOT_PATH } from '../../Shared/Constants/route';
import RemoveItemRequest from 'Models/Cart/RemoveItemRequest.interface';
import SetQuantityRequest from 'Models/Cart/SetQuantityRequest.interface';
import SetSelectedIssueRequest from 'Models/Cart/SetSelectedIssueRequest.interface';
import VariationModel from 'Models/KexVariation/VariationModel.interface';
import { gtmPushEvent } from 'Shared/GTM/GtmPageEventHandler';
import GoogleTagManagerEvent from 'Models/GoogleTagManager/GoogleTagManagerEvent.interface';

let abortController: AbortController = new AbortController();
let hasMounted = false;
export const cartUrl = API_ROOT_PATH + '/cart/';

type CartLoading = {
  cart: KexCartModel | null;
  isLoading: true;
  isCartEmpty: true;
  triggerRevalidation?: () => void;
};

type CartNotLoading = {
  cart: KexCartModel;
  isLoading: false;
  isCartEmpty: boolean;
  triggerRevalidation: () => void;
};

class AddRemoveEvent implements GoogleTagManagerEvent {
  eventId: string;
  ecommerceData: any;

  constructor(eventId: string, payload: any) {
    this.eventId = eventId;
    this.ecommerceData = {
      ecommerce: payload,
    };
  }
}

type CartReturnType = CartLoading | CartNotLoading;

export function GetCart(languageRoute: string): CartReturnType {
  const [returnType, setReturnType] = useState<CartReturnType>({
    cart: null,
    isLoading: true,
    isCartEmpty: true,
  });
  const { data: fetchedCart, mutate } = useSWR(
    `${cartUrl}GetCart?language=` + languageRoute,
    (url: string) => {
      return FetchCart(url);
    },
    {
      fallbackData: undefined,
      revalidateOnFocus: IS_PRODUCTION_ENV,
    }
  );

  useEffect(() => {
    if (!hasMounted) {
      hasMounted = true;
    } else {
      if (fetchedCart) {
        setReturnType({
          cart: fetchedCart,
          isLoading: false,
          isCartEmpty: fetchedCart.numberOfItems === 0,
          triggerRevalidation: () => mutate(fetchedCart),
        });
      }
    }
  }, [fetchedCart, mutate]);

  return returnType;
}

export async function RemoveFromCart(
  variation: VariationModel,
  languageRoute: string
) {
  gtmPushEvent(
    new AddRemoveEvent('remove_from_cart', variation.gtmAddRemoveToCartEvent)
  );

  const payload: RemoveItemRequest = {
    code: variation.code,
  };

  const queryParams = {
    language: languageRoute,
  };

  const queryString = new URLSearchParams(queryParams).toString();

  const res = await fetch(`${cartUrl}RemoveItem?${queryString}`, {
    method: 'DELETE',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(payload),
  });

  if (res.ok) {
    const { cart } = await res.json();
    mutate(`${cartUrl}GetCart?language=` + languageRoute, cart, false);
  }
}

/**
 * Sets the item with the requested quantity in cart
 * (also sets item if not in cart)
 *
 * @param {string} productCode - Product Code
 * @param {number} quantity - Product Qunatity
 * @param {string } languageRoute - languageRoute
 */
export async function SetQuantity(
  variation: VariationModel,
  quantity: number,
  currentQuantity: number,
  languageRoute: string
) {
  if (currentQuantity > quantity) {
    gtmPushEvent(
      new AddRemoveEvent('remove_from_cart', variation.gtmAddRemoveToCartEvent)
    );
  } else {
    gtmPushEvent(
      new AddRemoveEvent('add_to_cart', variation.gtmAddRemoveToCartEvent)
    );
  }

  const payload: SetQuantityRequest = {
    code: variation.code,
    quantity: quantity,
  };

  const queryParams = {
    language: languageRoute,
  };

  const queryString = new URLSearchParams(queryParams).toString();

  const res = await fetch(`${cartUrl}SetQuantity?${queryString}`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(payload),
  });
  if (res.ok) {
    const { cart, notification } = await res.json();
    EventDispatcher.dispatch(NOTIFY_ACTION, notification);
    mutate(`${cartUrl}GetCart?language=` + languageRoute, cart, false);
  }
}

/**
 * Adds the requested quantity to existing quantity on cart item
 * (also sets item if not in cart)
 *
 * @param {string} productCode - Product Code
 * @param {number} quantity - Product Qunatity
 * @param {string } languageRoute - languageRoute
 */
export async function UpdateQuantity(
  variation: VariationModel,
  quantity: number,
  languageRoute: string
) {
  gtmPushEvent(
    new AddRemoveEvent('add_to_cart', variation.gtmAddRemoveToCartEvent)
  );

  const payload: UpdateCartRequest = {
    code: variation.code,
    quantity: quantity,
  };

  const queryParams = {
    language: languageRoute,
  };

  const queryString = new URLSearchParams(queryParams).toString();

  const res = await fetch(`${cartUrl}UpdateQuantity?${queryString}`, {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(payload),
  });
  if (res.ok) {
    const { cart } = await res.json();
    mutate(`${cartUrl}GetCart?language=` + languageRoute, cart, false);
  }
}

export async function EmptyCart(languageRoute: string) {
  mutate(`${cartUrl}GetCart`, { numberOfItems: 0 } as KexCartModel, false);

  const res = await fetch(`${cartUrl}RemoveAllItems`, {
    method: 'DELETE',
  });

  if (res.ok) {
    const { cart } = await res.json();
    mutate(`${cartUrl}GetCart?language=` + languageRoute, cart, false);
  }
}

export async function FetchCartAndNotifyAll(languageRoute: string) {
  const res = await fetch(`${cartUrl}GetCart?language=` + languageRoute, {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
  });

  if (res.ok) {
    const { cart } = await res.json();
    mutate(`${cartUrl}GetCart?language=` + languageRoute, cart, false);
  }
}

function FetchCart(url: string) {
  abortController.abort();
  abortController = new AbortController();
  const { signal } = abortController;

  return Fetcher<KexCartModel, any>(url, signal, (data, resolve) => {
    resolve(data.cart);
  });
}

/**
 * Set selected issue for a subscription offer.
 * Action will be ignored if called with a code from regular products.
 *
 * @param {string} code - Product Code
 * @param {string} SetSelectedIssue - Product Qunatity
 * @param {string } languageRoute - languageRoute
 */
export async function SetSelectedIssue(
  code: string,
  selectedIssueId: string,
  languageRoute: string
) {
  const payload: SetSelectedIssueRequest = {
    code: code,
    selectedIssueId: selectedIssueId,
  };

  const queryParams = {
    language: languageRoute,
  };

  const queryString = new URLSearchParams(queryParams).toString();

  // Add a delay of 2 seconds (2000 milliseconds)
  await new Promise((resolve) => setTimeout(resolve, 500));

  const res = await fetch(`${cartUrl}SetSelectedIssue?${queryString}`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(payload),
  });
  if (res.ok) {
    const { cart } = await res.json();
    mutate(`${cartUrl}GetCart?language=` + languageRoute, cart, false);
  }
}
